/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i&display=swap); */
@import url(./font.css);

body,
html {
  height: 100%;
  width: 100%;
}

#kb-target {
  height: 100%;
}

.searchRoot,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

.feedbackRoot .reaction:focus,
.feedbackRoot .text textarea:focus,
.searchRoot .searchInput:focus {
  outline: 0;
}

.articleBody table:not(.elev-table),
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.footer a,
.header h1 a {
  text-decoration: none;
}

.articleBody .fr-text-uppercase,
.show-more {
  text-transform: uppercase;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

.searchRoot {
  position: relative;
  width: 100%;
}

.searchRoot.isOpen .searchIconContainer svg {
  fill: #607d8b;
}

.searchRoot .searchInputContainer {
  display: -webkit-box;
  display: flex;
  position: relative;
  z-index: 10;
}

.searchRoot .searchIconContainer {
  padding: 14px 0 0 20px;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.searchRoot .searchIconContainer svg {
  transition: fill 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  margin-top: 3px;
  fill: #949a9f;
  width: 13px;
  height: 13px;
}

.searchRoot .searchInput {
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  border: 0;
  background-color: #fff;
  -webkit-box-flex: 1;
  flex: 1;
  padding: 14px 60px 14px 10px;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
}

.searchRoot .searchInput::-webkit-input-placeholder {
  color: #949a9f;
  font-style: italic;
}

.searchRoot .searchInput::-moz-placeholder {
  color: #949a9f;
  font-style: italic;
}

.searchRoot .searchInput::-ms-clear {
  display: none;
}

.feedbackRoot:after,
.feedbackRoot:before {
  content: " ";
  display: table;
}

.searchRoot .hasResults .searchInput {
  border-bottom-right-radius: 0;
}

.searchRoot .hasResults .searchIconContainer {
  border-bottom-left-radius: 0;
}

.searchRoot .rightIcon {
  position: absolute;
  right: 20px;
  top: 16px;
  line-height: 0;
}

.searchRoot .closeButtonContainer {
  transition: background-color 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  line-height: 0;
  padding: 5px;
  cursor: pointer;
  background-color: #949a9f;
  border-radius: 50%;
}

.searchRoot .closeButtonContainer svg {
  width: 9px;
  height: 9px;
  fill: #fff;
}

.searchRoot .closeButtonContainer:hover {
  background-color: #607d8b;
}

.searchRoot .dropdown {
  z-index: 9;
  position: relative;
}

.searchRoot .mainViewContainer {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #f2f2f2;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 15px 15px;
}

.searchRoot .mainLoadingView,
.searchRoot .noResults {
  padding-top: 15px;
  text-align: center;
}

.searchRoot .noResults {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  max-width: 300px;
  margin: 0 auto;
  line-height: 22px;
}

.searchRoot .noResults a,
.searchRoot .noResults span {
  color: #4a90e2;
}

.feedbackRoot {
  padding: 30px 0;
  border-top: 1px solid #f3f5f7;
  border-bottom: 1px solid #f3f5f7;
}

.feedbackRoot:after {
  clear: both;
}

.feedbackRoot .reactionRoot {
  margin: 0 auto;
}

.feedbackRoot .reactionLabel {
  text-align: center;
  color: #8e9fa5;
  margin-bottom: 15px;
  font-size: 16px;
}

.feedbackRoot .btnContainer {
  text-align: center;
  margin-top: 5px;
}

.feedbackRoot .reaction {
  box-sizing: border-box;
  cursor: pointer;
  line-height: 0;
  border-radius: 50%;
  margin: 0 4px;
  border: 2px solid #c1d7de;
  padding: 15px;
  transition: all 0.25s ease-out;
  opacity: 0.5;
}

.footer a,
.footer svg {
  transition: color 0.25s ease-out, fill 0.25s ease-out;
}

.feedbackRoot .reaction svg {
  width: 15px;
  height: 15px;
  fill: #c1d7de;
}

.feedbackRoot .reaction.down {
  border-color: #da4429;
}

.feedbackRoot .reaction.down svg {
  fill: #da4429;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.feedbackRoot .reaction.up {
  border-color: #15cd72;
}

.feedbackRoot .reaction.up svg {
  fill: #15cd72;
}

.feedbackRoot .reaction:hover.down svg,
.feedbackRoot .reaction:hover.up svg {
  fill: #fff;
}

.feedbackRoot .reaction:hover {
  opacity: 1;
}

.feedbackRoot .reaction:hover.down {
  background-color: #da4429;
}

.feedbackRoot .reaction:hover.up {
  background-color: #15cd72;
}

.feedbackRoot .textLabel {
  color: #8e9fa5;
  margin-bottom: 15px;
  font-size: 16px;
}

.feedbackRoot .text {
  padding: 0 30px;
}

.feedbackRoot .email,
.feedbackRoot .text textarea {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  line-height: 1.33;
  font-weight: 400;
}

.feedbackRoot .text textarea {
  border: none;
  border-bottom: 2px solid #1392b8;
  color: #565867;
  resize: none;
  height: 51px;
  font-size: 14px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.feedbackRoot .text textarea::-webkit-input-placeholder {
  color: #cfd7df;
  font-style: italic;
}

.feedbackRoot .text textarea::-moz-placeholder {
  color: #cfd7df;
  font-style: italic;
}

.feedbackRoot .text textarea::-ms-clear {
  display: none;
}

.feedbackRoot .email {
  background-color: #fff;
  font-family: inherit !important;
  border: 0 !important;
  margin: 5px 0 15px !important;
  border-bottom: 2px solid #1392b8 !important;
  color: #565867;
  font-size: 14px;
}

.feedbackRoot .skip,
.feedbackRoot .submit {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
}

.feedbackRoot .email:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-bottom: 2px solid #1392b8 !important;
}

.button:focus,
.feedbackRoot .skip:focus,
.feedbackRoot .submit:focus,
.show-more:focus {
  outline: 0;
}

.feedbackRoot .email::-webkit-input-placeholder {
  color: #cfd7df;
  font-style: italic;
}

.feedbackRoot .email::-moz-placeholder {
  color: #cfd7df;
  font-style: italic;
}

.feedbackRoot .email::-ms-clear {
  display: none;
}

.feedbackRoot .submit {
  background-color: #1392b8;
  color: #fff;
  border: none;
  border-radius: 3px;
}

.feedbackRoot .submit.invalid {
  cursor: not-allowed;
}

.feedbackRoot .error {
  color: #da4429;
  font-size: 14px;
}

.feedbackRoot .skip {
  background-color: transparent;
  color: #8e9fa5;
  border: none;
  margin-left: 10px;
}

.feedbackRoot .success {
  color: #8e9fa5;
  font-size: 16px;
  text-align: center;
}

.feedbackRoot .emailView {
  padding: 0 30px;
}

.articleFeaturedImage {
  display: block;
  height: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  max-width: 100%;
}

.articleTitleRoot {
  display: block;
  margin-top: 15px;
}

.articleTitleRoot .title {
  display: inline;
  color: #4a90e2;
  font-size: 14px;
  font-weight: 400;
}

.articleTitleRoot .title div {
  display: inline;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom,
    rgba(74, 144, 226, 0.6) 50%,
    rgba(74, 144, 226, 0) 50%
  );
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.16em;
}

.articleTitleRoot .title em {
  font-style: normal;
  cursor: pointer;
  font-weight: fontWeightMedium;
  background-color: rgba(231, 249, 254, 0.7);
  border-radius: borderRadiusMedium;
  margin: 0 -2px;
  padding: 1px;
}

.articleBody address,
.articleBody cite,
.articleBody dfn,
.articleBody em,
.articleBody i,
.articleBody var {
  font-style: italic;
}

.loadingRoot {
  display: inline-block;
  border: 2px solid #4a90e2;
  border-left: 2px solid transparent;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation: load-animation 0.7s infinite linear;
  animation: load-animation 0.7s infinite linear;
}

@-webkit-keyframes load-animation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load-animation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.articleTitle {
  font-family: elevio-noto-sans, Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 25px;
  margin-top: 0;
}

.articleBody .clearfix::after,
.articleBody .row::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.articleBody .hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.articleBody .fr-img-caption.fr-rounded img,
.articleBody img.fr-rounded {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background-clip: padding-box;
}

.articleBody .fr-img-caption.fr-bordered img,
.articleBody img.fr-bordered {
  border: 5px solid #ccc;
}

.articleBody img.fr-bordered {
  box-sizing: content-box;
}

.articleBody .fr-img-caption.fr-bordered img {
  box-sizing: border-box;
}

.articleBody span[style~="color:"] a {
  color: inherit;
}

.articleBody table.elev-table {
  border: none;
  border-collapse: collapse;
  empty-cells: show;
  max-width: 100%;
}

.articleBody table.elev-table td {
  min-width: 5px;
}

.articleBody table.elev-table.fr-dashed-borders td,
.articleBody table.elev-table.fr-dashed-borders th {
  border-style: dashed;
}

.articleBody table.elev-table.fr-alternate-rows tbody tr:nth-child(2n) {
  background: #f5f5f5;
}

.articleBody table.elev-table td,
.articleBody table.elev-table th {
  border: 1px solid #ddd;
  padding: 0.325rem 0.8375rem;
}

.articleBody table.elev-table td:empty,
.articleBody table.elev-table th:empty {
  height: 20px;
}

.articleBody table.elev-table td.fr-highlighted,
.articleBody table.elev-table th.fr-highlighted {
  border: 1px double red;
}

.articleBody table.elev-table td.fr-thick,
.articleBody table.elev-table th.fr-thick {
  border-width: 2px;
}

.articleBody table.elev-table th {
  background: #ececec;
  padding: 0.325rem 0.8375rem;
}

.articleBody .fr-class-highlighted,
.articleBody .fr-view .fr-class-highlighted {
  background-color: #ff0;
}

.articleBody .fr-file {
  position: relative;
}

.articleBody span.fr-emoticon {
  font-weight: 400;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji,
    "Segoe UI Symbol", "Android Emoji", EmojiSymbols;
  display: inline;
  line-height: 0;
}

.articleBody span.fr-emoticon.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -0.1em 0.1em 0.1em;
  line-height: 1;
  vertical-align: middle;
}

.articleBody .fr-text-gray {
  color: #aaa !important;
}

.articleBody a.fr-green,
.articleBody a.fr-view.fr-green {
  color: green;
}

.articleBody .fr-text-bordered {
  border-top: solid 1px #222;
  border-bottom: solid 1px #222;
  padding: 10px 0;
}

.articleBody .fr-text-spaced {
  letter-spacing: 1px;
}

.articleBody .fr-class-code {
  border-color: #ccc;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background: #f5f5f5;
  padding: 10px;
  font-family: "Courier New", Courier, monospace;
}

.articleBody .fr-class-transparency {
  opacity: 0.5;
}

.articleBody img.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
}

.articleBody img.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.articleBody img.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

.articleBody img.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}

.articleBody img.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}

.articleBody img.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}

.articleBody span.fr-img-caption.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
}

.articleBody span.fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.articleBody span.fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

.articleBody span.fr-img-caption.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}

.articleBody span.fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}

.articleBody span.fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}

.articleBody .fr-video {
  text-align: center;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  display: block;
  clear: both;
}

.articleBody .fr-video embed,
.articleBody .fr-video object,
.articleBody .fr-video > iframe,
.articleBody .fr-video > video {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.articleBody .fr-video > * {
  box-sizing: content-box;
  max-width: 100%;
  border: none;
}

.articleBody .fr-video.fr-fvl {
  text-align: left;
}

.articleBody .fr-video.fr-fvr {
  text-align: right;
}

.articleBody a.fr-strong {
  font-weight: 700;
}

.articleBody .fr-img-caption {
  text-align: center;
}

.articleBody .fr-img-caption .fr-img-wrap {
  padding: 0;
  display: inline-block;
  margin: auto;
  text-align: center;
  width: 100%;
}

.articleBody .fr-img-caption .fr-img-wrap img {
  display: block;
  margin: auto;
  width: 100%;
}

.articleBody .fr-img-caption .fr-img-wrap > span {
  margin: auto;
  display: block;
  padding: 5px 5px 10px;
  font-size: 14px;
  font-weight: initial;
  box-sizing: border-box;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  width: 100%;
  text-align: center;
}

.articleBody button.fr-rounded,
.articleBody input.fr-rounded,
.articleBody textarea.fr-rounded {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background-clip: padding-box;
}

.articleBody button.fr-large,
.articleBody input.fr-large,
.articleBody textarea.fr-large {
  font-size: 24px;
}

.articleBody a.fr-view.fr-strong {
  font-weight: 700;
}

.articleBody img {
  position: relative;
}

.articleBody img.fr-view.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
}

.articleBody img.fr-view.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.articleBody img.fr-view.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

.articleBody img.fr-view.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}

.articleBody img.fr-view.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}

.articleBody img.fr-view.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}

.articleBody span.fr-img-caption {
  position: relative;
  max-width: 100%;
}

.articleBody span.fr-img-caption.fr-view.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
}

.articleBody span.fr-img-caption.fr-view.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.articleBody span.fr-img-caption.fr-view.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

.articleBody span.fr-img-caption.fr-view.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}

.articleBody span.fr-img-caption.fr-view.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}

.articleBody span.fr-img-caption.fr-view.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}

.articleBody iframe,
.articleBody img,
.container {
  max-width: 100%;
}

.articleBody span.fr-video:not(.fr-dvi) {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}
.articleBody span.fr-video:not(.fr-dvi).vertical-video {
  padding-bottom: 170%;
}

.articleBody span.fr-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.col,
.footer svg {
  position: relative;
}

.articleBody h1,
.articleBody h2,
.articleBody h3,
.articleBody h4,
.articleBody h5,
.articleBody h6 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.5;
}

.articleBody h1 {
  font-size: 26px;
}

.articleBody h2 {
  font-size: 24px;
}

.articleBody h3 {
  font-size: 22px;
}

.articleBody h4 {
  font-size: 20px;
}

.articleBody h5 {
  font-size: 18px;
}

.articleBody h6,
.articleBody p {
  font-size: 16px;
}

.articleBody p {
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 30px;
}

.articleBody b,
.articleBody dt,
.articleBody strong {
  font-weight: 700;
}

.articleBody [data-elevio-article],
.articleBody [data-elevio-module],
.articleBody a,
.articleBody span[role="link"] {
  cursor: pointer;
  color: #4a90e2;
  text-decoration: none;
  background-image: linear-gradient(
    to bottom,
    rgba(74, 144, 226, 0.6) 50%,
    rgba(74, 144, 226, 0) 50%
  );
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.16em;
  background-color: transparent;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.articleBody a:active,
.articleBody a:hover {
  outline-width: 0;
}

.articleBody h1 img,
.articleBody h2 img,
.articleBody h3 img,
.articleBody h4 img,
.articleBody h5 img,
.articleBody h6 img,
.articleBody li img,
.articleBody p img {
  margin: 0;
}

.articleBody div > img {
  margin: 10px 0;
}

.articleBody img {
  border-style: none;
  margin: 30px 0;
  height: auto !important;
  vertical-align: initial;
}

.articleBody small {
  font-size: 80%;
}

.articleBody hr {
  clear: both;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  page-break-after: always;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #f2f2f2;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.articleBody blockquote {
  padding: 10px 20px;
  margin: 30px 0;
  font-size: 16px;
  border-left: 5px solid #eee;
}

.articleBody blockquote a {
  font-size: 16px;
}

.articleBody blockquote.elevio-box-info {
  border-radius: 4px;
  border: 0;
  background-color: rgba(187, 222, 251, 0.6);
}

.articleBody blockquote.elevio-box-warning {
  border-radius: 4px;
  border: 0;
  background-color: rgba(255, 224, 178, 0.6);
}

.articleBody blockquote.elevio-box-success {
  border-radius: 4px;
  border: 0;
  background-color: rgba(200, 230, 201, 0.6);
}

.articleBody blockquote.elevio-box-error {
  border-radius: 4px;
  border: 0;
  background-color: rgba(255, 205, 210, 0.6);
}

.articleBody blockquote ol:last-child,
.articleBody blockquote p:last-child,
.articleBody blockquote ul:last-child {
  margin-bottom: 0;
}

.articleBody blockquote footer,
.articleBody blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: rgba(0, 0, 0, 0.8);
}

.articleBody blockquote footer:before,
.articleBody blockquote small:before {
  content: "\2014 \00A0";
}

.articleBody ol,
.articleBody ul {
  margin: 30px 0;
  padding-left: 30px;
}

.articleBody ol {
  list-style-type: decimal;
}

.articleBody ul {
  list-style-type: disc;
}

.articleBody ol ol,
.articleBody ol ul,
.articleBody ul ol,
.articleBody ul ul {
  margin-bottom: 0;
  margin-top: 0;
}

.articleBody ol ol {
  list-style-type: lower-alpha;
}

.articleBody ol ol ol {
  list-style-type: lower-roman;
}

.articleBody li {
  list-style-type: inherit;
  margin: 10px 0;
}

.articleBody abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.articleBody mark {
  background-color: #fcf8e3;
  padding: 0.2em;
  color: #000;
}

.articleBody article,
.articleBody aside,
.articleBody details,
.articleBody figcaption,
.articleBody figure,
.articleBody footer,
.articleBody header,
.articleBody main,
.articleBody menu,
.articleBody nav,
.articleBody section {
  display: block;
}

.articleBody figure {
  margin: 1em 40px;
}

.articleBody [hidden],
.articleBody template {
  display: none;
}

.articleBody audio,
.articleBody video {
  display: inline-block;
}

.articleBody audio:not([controls]) {
  display: none;
  height: 0;
}

body,
html {
  height: 100%;
}

.articleBody svg:not(:root) {
  overflow: hidden;
}

.articleBody summary {
  display: list-item;
}

.articleBody canvas {
  display: inline-block;
}

.articleBody sub,
.articleBody sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.articleBody address,
.articleBody dd,
.articleBody dt,
.articleBody pre {
  line-height: 1.42857143;
}

.articleBody sub {
  bottom: -0.25em;
}

.articleBody sup {
  top: -0.5em;
}

.articleBody dl {
  margin-top: 0;
  margin-bottom: 20px;
}

.articleBody dd {
  margin-left: 0;
}

.articleBody address {
  margin-bottom: 20px;
  font-style: normal;
}

.articleBody code,
.articleBody kbd,
.articleBody pre,
.articleBody samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 1em;
}

.articleBody kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.articleBody kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

.articleBody pre {
  margin: 30px 0;
  overflow-x: auto;
  display: block;
  padding: 9.5px;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: pre-wrap;
}

.articleBody pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.articleBody code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.articleBody table:not(.elev-table) {
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.articleBody table:not(.elev-table) td,
.articleBody table:not(.elev-table) th {
  padding: 0;
}

.articleBody table:not(.elev-table) th {
  text-align: left;
}

.articleBody table:not(.elev-table) > tbody > tr > td,
.articleBody table:not(.elev-table) > tbody > tr > th,
.articleBody table:not(.elev-table) > tfoot > tr > td,
.articleBody table:not(.elev-table) > tfoot > tr > th,
.articleBody table:not(.elev-table) > thead > tr > td,
.articleBody table:not(.elev-table) > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.articleBody table:not(.elev-table) > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.articleBody table:not(.elev-table) > caption + thead > tr:first-child > td,
.articleBody table:not(.elev-table) > caption + thead > tr:first-child > th,
.articleBody table:not(.elev-table) > colgroup + thead > tr:first-child > td,
.articleBody table:not(.elev-table) > colgroup + thead > tr:first-child > th,
.articleBody table:not(.elev-table) > thead:first-child > tr:first-child > td,
.articleBody table:not(.elev-table) > thead:first-child > tr:first-child > th {
  border-top: 0;
}

.articleBody table:not(.elev-table) > tbody + tbody {
  border-top: 2px solid #ddd;
}

.articleBody table::not(.elev-table) table {
  background-color: #fff;
}

@media print {
  #article-feedback,
  .header-wrap,
  .sidebar-container {
    display: none;
  }

  .fr-video {
    display: none !important;
  }
}

*,
::after,
::before {
  box-sizing: inherit;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4d4d61;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header h1,
.show-more,
strong {
  font-weight: 700;
}

.button,
.show-more {
  border-radius: 4px;
  padding: 6px 8px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.right {
  float: right;
}

.clearfix::after,
.row::after {
  display: block;
  content: "";
  clear: both;
}

.container {
  width: 1100px;
  margin: 0 auto;
}

.col,
.col-sm-12 {
  width: 100%;
}

.container.article-show,
.container.category-index,
.container.index {
  padding: 40px 0;
}

.col {
  float: left;
  padding: 0 30px;
  min-height: 1px;
}

.footer,
.header,
.search-container {
  padding: 40px 0;
}

@media (min-width: 700px) {
  .col-md-6 {
    width: 50%;
  }

  .col-md-4 {
    width: 33.3333333333%;
  }

  .col-md-8 {
    width: 66.6666666667%;
  }

  .col-push-md-4 {
    left: 33.3333333333%;
  }

  .col-pull-md-8 {
    right: 66.6666666667%;
  }
}

@media (min-width: 900px) {
  .col-lg-4 {
    width: 33.3333333333%;
  }
}

.header h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.header h4 {
  font-size: 18px;
  line-height: 24px;
}

.search-attach {
  min-height: 46px;
  max-width: 700px;
  margin: 0 auto;
}

.footer {
  background-color: #fff;
  color: #a7a7b9;
  border-top: 1px solid #e0e0e6;
  font-size: 12px;
}

.footer a {
  color: #6f6f8c;
}

.footer a.underline {
  text-decoration: underline;
}

.category-title a,
.related-articles h4,
.show-more,
.stale-link,
.subcategory-link a {
  text-decoration: none;
}

.footer a:hover {
  color: #0091ea;
}

.footer a:hover svg {
  fill: #0091ea;
}

.footer svg {
  top: 2px;
  margin-left: 2px;
  fill: #a7a7b9;
  width: 53px;
  height: 15px;
}

.category-section {
  margin-bottom: 0;
  padding: 25px 0;
}

.show-more {
  color: #fff;
  font-size: 10px;
  padding: 4px 8px;
}

.stale-link {
  color: pink;
  cursor: default;
}

.articles-list {
  margin-bottom: 40px;
}

.related-articles h4 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.3px;
  color: #4d4d61;
}

.article-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 30px;
}

.back-link,
.category-title h4 {
  margin-bottom: 20px;
}

.back-link a {
  display: inline-block;
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.category-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.3px;
  color: #4d4d61;
}

.subcategory-link {
  margin-bottom: 6px;
  border-radius: 4px;
  padding: 4px 12px 4px 8px;
  background-color: #f4f4f6;
  line-height: 22px;
  color: #4d4d61;
  font-weight: 700;
}

.subcategory-link.last {
  margin-bottom: 20px;
}

.subcategory-link svg {
  width: 8px;
  height: 8px;
  fill: #4d4d61;
  margin-right: 6px;
}

.article-link {
  margin-bottom: 6px;
}

.article-link.last {
  margin-bottom: 12px;
}

.article-link a {
  line-height: 20px;
  color: #4d4d61;
  transition: color 0.2s ease-out;
}

.article-link a:hover {
  color: #15151a;
}

.article-link a.active {
  font-weight: 700;
  text-decoration: none;
}

.sidebar-fixed {
  overflow-y: auto;
}

.site-link {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 24px;
  padding-right: 10px;
}

.site-link svg {
  margin-right: 3px;
}

.alert-box {
  padding: 20px;
  border-radius: 3px;
  margin: 20px 0;
}

.alert-box.error {
  background-color: #f2dede;
}

.alert-box.success {
  background-color: #dff0d8;
}

.loadingContainer {
  margin: 50px auto;
  width: 20px;
}

.feedbackRoot.error {
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.root {
  display: inline-block;
  border: 2px solid #4a90e2;
  border-left: 2px solid transparent;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  -webkit-animation: rotate360 0.7s infinite linear;
  animation: rotate360 0.7s infinite linear;
}

@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion) {
  .root {
    animation: none 0s ease-in;
    transform: rotate(360deg);
  }
}

.access-banner-wrapper {
  display: none;
  font-size: 0.9rem;
  flex-direction: column;
  color: currentColor;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
}

.audience-internal.access-banner-wrapper {
  color: #da4429;
}

.audience-external.access-banner-wrapper {
  color: #15cd72;
}

.access-icon-container {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.access-message-container {
  display: flex;
  align-items: center;
}

.access-banner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: currentColor;
}

.access-banner-message {
  margin-left: 1rem;
}

.access-banner-chevron {
  fill: currentColor;
  width: 20px;
}

.access-banner-list {
  padding-left: 2.5rem;
  padding-top: 0.5rem;
  list-style: disc;
}

.access-banner-down-icon {
  padding: 0.25rem;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* Lightbox styles*/
.lightbox-image {
  display: block;
  border: none !important;
  background: white;
  width: auto;
  max-width: 100%;
}

.lightbox-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.75);
}

.lightbox-modal-content {
  position: relative;
  margin: auto;
  padding: 0;
}

.lightbox-image-slide {
  width: 100%;
}

.lightbox-close {
  fill: white;
  position: absolute;
  top: 10px;
  right: 25px;
  width: 16px;
  font-weight: bold;
}

.lightbox-close svg {
  width: 100%;
}

.lightbox-close:hover,
.lightbox-close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.article-lightbox img {
  cursor: pointer;
}
